/* Detail Page */

.detail-container {
  /* border: 1px solid red; */
  /* margin-top: 28px !important; */
  /* height: calc(100% - 28px); */
  /* height: calc(100vh - 100px); */
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}

.top-bar {
  width: 40px;
  height: 40px;
  /* background-color: #ffff; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 4px;
  padding-left: 10px;
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0; /* Stick the element to the top of its container */
  cursor: pointer;
  /* background-color: yellow; */
  z-index: 1;
}
.back {
  width: 30px;
  background-color: #ffff !important;
  color: #ffff !important;
}

.maps-chant-container {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 8px;
  width: 100%;
  max-width: 260px;
  /* height: calc(100vh - 140px); */
  margin: 0 auto;
  margin-top: 12px;

  /* background-color: red; */
}

.location {
  font-family: "Mooli";
  font-size: 30px;
  color: #ffff;
  font-weight: 600;
}

.detail {
  font-size: 16px;
  color: #ffff;
  font-family: "Mooli";
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.chat-container {
  /* max-height: 300px; */
  position: relative;
  width: 100%;
  max-width: 326px;
  /* height: 100%; */
  /* max-height: calc(100vh - 300px); */
  /* margin: 0 4px; */
  padding: 0 8px;

  /* height: 100vh; */
  /* height: calc(50vh - 70px); */
  /* max-height: calc(100vh - 200px); */
  /* border: 1px solid red; */
  /* background-color: #ffff; */

  height: 100vh;
  /* max-height: calc(100vh - 300px); */
  max-height: calc(var(--vh) - 240px);
  overflow-y: auto;
  scrollbar-width: none;
  padding-bottom: env(safe-area-inset-bottom);

  overflow: scroll;
  color: #ffff;
  font-family: "Poppins";

  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 90%,
    rgba(255, 255, 255, 0.1) 100%
  );
}

/* @media only screen and (min-width: 380px) {
  .chat-container {
    max-height: calc(100vh - 60px) !important;
  }
} */

.chat {
  /* display: flex; */
  margin-bottom: 8px;
}

.chat-user {
  width: 100%;
  max-width: 160px;
  font-size: 14px;
  /* border: 1px solid white; */
  text-align: left;
}

.chat-message {
  width: 100%;
  /* border: 1px solid red; */
  text-align: left;
  max-width: 80%;
  font-size: 12px;
}

.chat-date {
  /* border: 3px solid red; */
  width: 100%;
  min-width: 40px;
  max-width: 80px;
  font-size: 10px;
  margin-top: 4px;
  margin-left: auto;
  text-align: right;
}

.chat-user-2 {
  width: 100%;
  max-width: 160px;
  font-size: 14px;
  /* border: 1px solid white; */
  /* float: right; */
  margin-left: auto;
  text-align: right;
}

.chat-message-2 {
  width: 100%;
  /* border: 1px solid red; */
  text-align: right;
  margin-left: auto;
  max-width: 80%;
  font-size: 12px;
}

.input-chat {
  /* border-top: 1px solid #d3d3d3; */

  padding: 4px;

  margin-top: 8px;
  /* border: 1px solid red;` */
  height: 100px;
}

.input-chat input {
  /* border: 1px solid red; */
  background-color: transparent !important;
  color: #ffff;
  padding: 4px;
  border-radius: 0px !important;
  border: 0 !important;
  border-bottom: 2px solid grey !important;
}

.input-name {
  display: block;
  /* height: 30px; */
  width: 160px;
  font-size: 16px;
}

.input-message {
  width: calc(100% - 40px);
  /* width: calc(100% - 12px); */
  /* height: calc(100% - 40px); */
  margin-top: 8px !important;
  font-size: 16px;
}

.my-chat {
}

.other-chat {
}

.fade-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px; /* Height of the fade effect */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

/* @media only screen and (min-width: 600px) {
  .maps-chant-container {
    flex-direction: row;
    max-width: calc(100% - 16px);
  }

  .chat-container {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    max-height: calc(100vh - 70px);
  }
} */
