* {
  margin: 0;
  padding: 0;
}

.App {
  height: 100%;
  overflow-y: hidden !important;
}

.App-header {
  height: 100%;
  overflow-y: hidden !important;
}

.body {
  background-image: url("/public/assets/background.jpg");
  background-size: cover; /* Ensures the image covers the entire element */
  background-position: center; /* Centers the image within the element */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-attachment: fixed; /* Fixes the image in place while scrolling */
  /* padding-bottom: env(safe-area-inset-bottom); */
  /* min-height: calc(var(--vh, 1vh) * 100); */
  /* min-height: calc(var(--vh, 1vh) * 100); */

  height: var(--vh);
  max-height: var(--vh);
  /* max-height: calc(100vh - 55px); */
  text-align: center;
  overflow-y: hidden !important;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1.7));
}

.heading-1 {
  font-size: 58px;
  font-weight: 800;
  font-style: normal;
}

.heading-2 {
  font-size: 34px;
  font-weight: 500;
  font-style: normal;
}

.lobster {
  font-family: "Lobster", sans-serif;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.text-white {
  color: white;
}
.cross-out {
  text-decoration: line-through;
}

.copyright {
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  font-family: "Poppins", sans-serif;
  font-size: 8px;
  color: #ffff;
  /* opacity: 30%; */
}
