.content-container {
  /* margin: 50px 0; */
  padding: 50px 0;
  height: calc(100% - 100px);
}

.title-container {
  width: fit-content;
  margin: 0 auto;
}
.title-1 {
  width: fit-content;
  /* margin-left: auto; */
}
.title-2 {
  width: fit-content;
  margin-left: 80px;
  margin-top: -20px;
}

.coming-soon-title {
  font-family: "Mooli", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
  color: #ffff;
  width: 300px;
  margin: 0 auto;
  margin-top: 20px;
}

.coming-soon-timer-wrapper {
  margin-top: 8px;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
}

.caption {
  font-family: "Mooli", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  color: #ffff;
  width: 300px;
  margin: 0 auto;
  margin-top: 30px;
  letter-spacing: 5px;
}

.caption-2 {
  font-family: "Mooli", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
  color: #ffff;
  width: 300px;
  margin: 0 auto;
  margin-top: 30px;
}

.button-detail {
  width: fit-content;
  padding: 8px;
  margin: 0 auto;
  margin-top: 8px;
  background-color: #ffff;
  cursor: pointer;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 600;
  border-radius: 8px;
}

.sponsor-container {
  position: absolute;
  bottom: 60px;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 14px;
  color: #ffff;
  width: 100%;
  /* margin: 0 auto; */
}

@media only screen and (max-width: 380px) {
  .sponsor-container {
    font-size: 10px;
    /* margin: 0 auto; */
  }
}

.sponsor {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}
