.police-line-container {
  position: relative;
  width: 100%;
  height: 30px; /* Adjust height as needed */
  overflow: hidden;
}

.police-line {
  position: absolute;
  width: 100%;
  height: 20px;
  z-index: 1;
}

/* .police-line {
  position: absolute;
  width: 100%;
  height: 30px;
  overflow: hidden;
  background: repeating-linear-gradient(
    45deg,
    yellow,
    yellow 10px,
    black 10px,
    black 20px
  );

  z-index: 1;
} */
