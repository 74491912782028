.countdown-container {
  position: relative;
  max-width: 80px;
}
.text-countdown {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffff;
  font-size: 16px;
  font-weight: 600;
  font-family: "Mooli";
}
